// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";

import * as ActiveStorage from "@rails/activestorage";
import "@fortawesome/fontawesome-free/css/all.css";

import "lazyload";
import "bootstrap";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js";

import "./ui";
import "./tabs";
import "./modal";
import "./forms";
import "./messages";
import "./flash_message";
import "./specialties";

import "channels";

import "./calls";
import "./conferences";

Rails.start();
ActiveStorage.start();

window.urlify = function (text) {
  if (typeof text !== 'string') {
    return text || '';
  }

  // Se o texto já contém um link <a>, retorna ele como está para evitar reconversão
  if (text.includes("<a ")) {
    return text;
  }

  var urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;
  var fileExtensions = /\.(pdf|jpg|jpeg|png|gif|bmp|docx|doc|xlsx|xls|ppt|pptx|zip|rar)$/i;

  return text.replace(urlRegex, function (url) {
    var formattedUrl = url.startsWith("www.") ? "http://" + url : url;
    var newTab = "";

    try {
      var urlObject = new URL(formattedUrl);

      if (fileExtensions.test(urlObject.pathname)) {
        newTab = ' target="_blank"';
      } else if (urlObject.hostname !== window.location.hostname) {
        newTab = ' target="_blank"';
      }

      return `<a href="${formattedUrl}"${newTab}>${url}</a>`;
    } catch (e) {
      return url;
    }
  }).replace(/\n/g, "<br>");
};


// SCHEDULE
(function () {
  let scheduleDateInterval;
  const today = new Date();
  const tomorrow = new Date();

  tomorrow.setDate(today.getDate() + 1);

  const day = String(tomorrow.getDate()).padStart(2, '0');
  const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
  const year = tomorrow.getFullYear();
  const formattedDate = day + '/' + month + '/' + year;

  const $scheduleModal = $("#scheduleModal");
  const $scheduleDate = $("#schedule-date");
  const $expectedMinutes = $("#expected_minutes");
  const $startTime = $("#start_time");
  const $submitButton = $scheduleModal.find("input[type='submit'][name='commit']");
  const $startAtInput = $scheduleModal.find(":input[name='start_at']");
  const $startTimeInput = $scheduleModal.find(":input[name='start_time']");
  const availableDates = $scheduleModal.data('available-days');

  let availableDaysWithTimes = {};
  let selectedDate = null;

  if (availableDates) {
    if (typeof availableDates === "object") {
      availableDaysWithTimes = availableDates;
    } else if (typeof availableDates === "string") {
      try {
        let jsonString = availableDates.replace(/"=>/g, '":').replace(/(\w+)=>/g, '"$1":');
        availableDaysWithTimes = JSON.parse(jsonString);
      } catch (e) {
        console.error("Erro ao processar availableDates:", e);
      }
    }
  }

  function isDateAvailable(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return Object.keys(availableDaysWithTimes).includes(formattedDate)
      ? { enabled: true, classes: 'available-date' }
      : { enabled: false, classes: 'disabled-date' };
  }

  function filterSelectOptions(maxDuration) {
    $expectedMinutes.find("option").each(function () {
      const optionValue = parseInt($(this).val(), 10);

      if (isNaN(optionValue)) {
        $(this).show();
      } else if (optionValue <= maxDuration) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });

    $expectedMinutes.val("");
  }

  function toggleStartTimeOptions(duration) {
    $startTime.find("option").hide(); // Oculta todas as opções primeiro

    // Se `availableDaysWithTimes[selectedDate]` for undefined, criamos uma lista com todos os horários possíveis
    if (!availableDaysWithTimes[selectedDate]) {
      availableDaysWithTimes[selectedDate] = [];

      for (let hour = 0; hour < 24; hour++) {
        for (let minute of [0, 15, 30, 45]) {
          let formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
          availableDaysWithTimes[selectedDate].push(`${formattedTime} - 23:59`); // Criando um range "genérico"
        }
      }
    }

    if (selectedDate && availableDaysWithTimes[selectedDate]) {
      // Segue a lógica de calcular quais horários devem ser mostrados
      availableDaysWithTimes[selectedDate].forEach(timeRange => {
        const [start, end] = timeRange.split(" - ");
        let [startHour, startMinute] = start.split(":").map(Number);
        const [endHour, endMinute] = end.split(":").map(Number);
        const startDate = new Date(0, 0, 0, startHour, startMinute);
        const endDate = new Date(0, 0, 0, endHour, endMinute);

        while (startDate.getTime() + duration * 60000 <= endDate.getTime() + 60000) {
          // Adiciona 60.000ms (1min extra) para garantir que o último horário (23:45) seja incluído corretamente

          const formattedStart = `${String(startDate.getHours()).padStart(2, '0')}:${String(startDate.getMinutes()).padStart(2, '0')}`;

          // Mostra apenas os horários disponíveis
          $startTime.find(`option[value="${formattedStart}"]`).show();
          startDate.setMinutes(startDate.getMinutes() + 15);
        }
      });

      $startTime.prop('disabled', false).val("");
    } else {
      // Caso contrário, exibe todos os horários
      $startTime.find("option").show();
      $startTime.prop('disabled', false).val("");
    }
  }

  function validateForm() {
    const durationSelected = $expectedMinutes.val() !== "";
    const startTimeSelected = $startTime.val() !== "";

    $submitButton.prop("disabled", !(durationSelected && startTimeSelected));
  }

  function getLastAvailableDate() {
    const dates = Object.keys(availableDaysWithTimes);

    if (dates.length === 0) return null;

    const formattedDates = dates.map(date => {
      const [day, month, year] = date.split('/');
      return new Date(`${year}-${month}-${day}T23:59:59`); // Garante o final do dia
    });

    return new Date(Math.max.apply(null, formattedDates));
  }

  let datepickerConfig = {
    format: "dd/mm/yyyy",
    startDate: "+0d",
    language: "pt-BR"
  };

  if ($scheduleModal.length && Object.keys(availableDates).length) {
    datepickerConfig.endDate = getLastAvailableDate();
    datepickerConfig.beforeShowDay = (date) => {
      const availability = isDateAvailable(date);

      return availability.enabled
        ? { classes: availability.classes }
        : false;
    }
  }

  $scheduleDate.datepicker(datepickerConfig).on("changeDate", function (e) {
    const date = e.date;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    selectedDate = `${day}/${month}/${year}`;

    $expectedMinutes.prop('disabled', false).val("");
    $startTime.prop('disabled', true).val("");
    $submitButton.prop("disabled", true);

    if (availableDaysWithTimes[selectedDate]) {
      let maxDuration = 0;

      availableDaysWithTimes[selectedDate].forEach(timeRange => {
        const [start, end] = timeRange.split(" - ");
        const [startHour, startMinute] = start.split(":").map(Number);
        const [endHour, endMinute] = end.split(":").map(Number);
        const startDate = new Date(0, 0, 0, startHour, startMinute);
        const endDate = new Date(0, 0, 0, endHour, endMinute);
        const duration = (endDate - startDate) / (1000 * 60);

        if (duration > maxDuration) {
          maxDuration = duration;
        }
      });

      filterSelectOptions(maxDuration);
    }
  });

  $expectedMinutes.on("change", function () {
    const duration = parseInt($(this).val(), 10);

    if ($scheduleModal.length && Object.keys(availableDates).length) {
      if (duration && selectedDate) {
        toggleStartTimeOptions(duration);
      } else {
        $startTime.prop('disabled', true).val("");
      }
    } else {
      toggleStartTimeOptions(duration);
      $startTime.prop('disabled', false).val("");
    }

    validateForm();
  });

  $startTime.on("change", validateForm);

  $scheduleModal.on("shown.bs.modal", function () {
    scheduleDateInterval = setInterval(() => {
      const scheduleDate = $scheduleDate.datepicker("getDate");

      if (scheduleDate) {
        const scheduleDateWithHour = new Date(scheduleDate);
        const scheduleDateStartTime = $startTimeInput.val();

        if (scheduleDateStartTime) {
          const [hours, minutes] = scheduleDateStartTime.split(":").map(Number);

          scheduleDateWithHour.setHours(hours);
          scheduleDateWithHour.setMinutes(minutes);
        }

        $startAtInput.val(scheduleDateWithHour);
      } else {
        $startAtInput.val("");
      }
    }, 300);
  });

  $scheduleModal.on("submit", function (e) {
    if (!$scheduleDate.datepicker("getDate")) {
      e.preventDefault();
      alert("Por favor, selecione uma data para o agendamento.");
    }
  });

  $scheduleModal.on("hidden.bs.modal", function () {
    clearInterval(scheduleDateInterval);
    $scheduleDate.datepicker("clearDates");
    $expectedMinutes.val("").prop('disabled', true);
    $startTime.val("").prop('disabled', true);
    $submitButton.prop("disabled", true);
  });
})();

window.playAudio = function ($element) {
  var audio = $($element)[0];

  audio
    .play()
    .then(() => {
      // console.log("Áudio reproduzido com sucesso!");
    })
    .catch((error) => {
      // console.error("Erro ao reproduzir áudio:", error);
    });
};

window.stopAudio = function ($element) {
  var audio = $($element)[0];

  audio.currentTime = 0;

  audio.pause();
};

window.addToast = function (message, icon) {
  var iconHTML = "";

  if ($("#liveToast").length) {
    $("#liveToast").remove();
  }

  if (icon) {
    iconHTML = `<i class="fas ${icon} mr-2 text-white"></i>`;
  }

  $("body").append(`
    <div id="liveToast" class="position-fixed p-3" style="z-index: 99999999; left: 0; bottom: 0;">
      <div class="toast bg-dark hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
        <div class="toast-body text-white">
          ${iconHTML}
          ${message}
        </div>
      </div>
    </div>
  `);

  $("#liveToast .toast").toast("show");
};

$(".modal").on("shown.bs.modal", function () {
  $(
    'img[src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="]',
  ).lazyload();
});

// share social_buttons

$(".social-button a").on("click", function (e) {
  e.preventDefault();
  window.open(
    $(this).attr("href"),
    "sharer",
    "toolbar=0,status=0,width=548,height=325",
  );
});

/* Javascript to show and hide cookie banner using localstorage */
/* Shows the Cookie banner */
var showCookieBanner = function () {
  $('#helpcam-cookie-banner').fadeIn(function () {
    $('#helpcam-cookie-banner .alert').slideDown();
  });
};

/* Hides the Cookie banner and saves the value to localstorage */
var hideCookieBanner = function () {
  localStorage.setItem('cookieAccepted', 'yes');

  $('#helpcam-cookie-banner .alert').slideUp(function () {
    $('#helpcam-cookie-banner').fadeOut();
  });
};

/* Checks the localstorage and shows Cookie banner based on it. */
var initializeCookieBanner = function () {
  var isCookieAccepted = localStorage.getItem('cookieAccepted');

  if (isCookieAccepted === null) {
    localStorage.setItem('cookieAccepted', 'no');
    showCookieBanner();
  }

  if (isCookieAccepted === 'no') {
    showCookieBanner();
  }

  $('#helpcam-cookie-banner .btn-primary').on('click', function (event) {
    var $element = $(event.target);

    event.stopPropagation();

    hideCookieBanner();
  });
};

// COOKIE BANNER
initializeCookieBanner();

// OFFICIAL LAUNCH
/* JavaScript to show and hide the official launch banner using localStorage */

/* Shows the Official Launch banner */
var showOfficialLaunchBanner = function () {
  $('#modal-official-launch').modal('show');
};

/* Hides the Official Launch banner and saves the value to localStorage */
var hideOfficialLaunchBanner = function () {
  localStorage.setItem('officialLaunchDismissed', 'yes');
  $('#modal-official-launch').modal('hide');
};

/* Checks localStorage and shows the Official Launch banner based on it */
var initializeOfficialLaunchBanner = function () {
  var isLaunchDismissed = localStorage.getItem('officialLaunchDismissed');

  if (!isLaunchDismissed || isLaunchDismissed === 'no') {
    showOfficialLaunchBanner();
  }

  $('#modal-official-launch .btn-primary').off('click').on('click', function (event) {
    event.stopPropagation();
    hideOfficialLaunchBanner();
  });

  // Ensure localStorage is cleared on logout
  $('a[href="/users/sign_out"]').off('click').on('click', function () {
    localStorage.removeItem('officialLaunchDismissed');
  });
};

initializeOfficialLaunchBanner();

// FIX ANCHOR
$(document).on('click', 'a[href*="#"]', function (event) {
  event.preventDefault();
  event.stopPropagation();

  var href = $(this).attr('href');
  var currentUrl = window.location.href.split('#')[0];
  var targetUrl = href.split('#')[0];

  if (currentUrl !== targetUrl) {
    window.location.href = href;
  } else {
    var anchor = href.split('#')[1];

    if (anchor) {
      var targetElement = $('#' + anchor);

      if (targetElement.length) {
        $('html, body').animate({
          scrollTop: targetElement.offset().top
        }, 500);
      }
    }
  }

  return false;
});

function setupAutocomplete(inputSelector, listSelector) {
  // Adjust the width of the autocomplete list to match the parent element's width
  const adjustWidth = () => {
    const inputWidth = $(inputSelector).outerWidth() - 26;  // Get width of the input element including padding and border

    $(listSelector).width(inputWidth);  // Set the width of the autocomplete list
  };

  // Filter and display list items based on the input
  const filterAndShowItems = (inputVal) => {
    let hasVisibleItems = false;

    $(`${listSelector} .list-group-item`).each(function() {
      const text = $(this).text().toLowerCase();

      if (text.startsWith(inputVal)) {
        $(this).show();
        hasVisibleItems = true;
      } else {
        $(this).hide();
      }
    });

    // Show or hide the list based on the visibility of items
    $(listSelector).toggle(hasVisibleItems);
  };

  // Handle input event
  $(inputSelector).on('input', function() {
    const inputVal = $(this).val().toLowerCase();

    filterAndShowItems(inputVal);
  });

  // Handle focus event to adjust width and show all items if the input is empty
  $(inputSelector).on('focus', () => {
    adjustWidth();

    if (!$(inputSelector).val().trim()) {
      $(`${listSelector} .list-group-item`).show();
      $(listSelector).show();
    } else {
      filterAndShowItems($(inputSelector).val().toLowerCase());
    }
  });

  // Handle blur event to hide the list with a delay
  $(inputSelector).on('blur', () => {
    setTimeout(() => {
      $(listSelector).hide();
    }, 200);
  });

  // Handle click event on list items to select the value
  $(document).on('click', `${listSelector} .list-group-item`, function() {
    $(inputSelector).val($(this).text()).blur();
  });

  // Adjust the list width when the window is resized
  $(window).resize(adjustWidth);
}

// Usage of the function
$(function() {
  setupAutocomplete('#autocomplete-input', '#autocomplete-list');
});

$(document).on('click', "a[data-toggle='tooltip'], button[data-toggle='tooltip']", function() {
  var currentTitle = $(this).attr('data-original-title');
  var alternateTitle = $(this).data('alt-title');

  if (alternateTitle) {
    if (currentTitle !== alternateTitle) {
      $(this).attr('data-original-title', alternateTitle);
      $(this).data('alt-title', currentTitle);

      $(this).tooltip('hide').tooltip('dispose');
      $(this).tooltip();

      $(this).tooltip('show');
    } else {
      $(this).tooltip('hide');
    }
  } else {
    $(this).tooltip('hide');
  }
});

$('html').fadeIn();

window.updateFileCount = function (input) {
  let count = input.files.length;
  let $fileCount = $("#file-count");

  if (count > 0) {
    $fileCount.text(count).removeClass("d-none").addClass("d-inline-block"); // Mostra a badge
  } else {
    $fileCount.addClass("d-none").removeClass("d-inline-block"); // Esconde a badge
  }
}
