import "inputmask";

import cep from "cep-promise";

import { isValid as isValidCpf } from "@fnando/cpf";
import { isValid as isValidCnpj } from "@fnando/cnpj";

function isValidTime(time) {
  const [hours, minutes] = time.split(":").map(Number);
  const validMinutes = [0, 15, 30, 45];
  return (
    (hours >= 0 && hours <= 23) &&
    validMinutes.includes(minutes)
  );
}

function isStartBeforeEnd(start, end) {
  const [startHours, startMinutes] = start.split(":").map(Number);
  const [endHours, endMinutes] = end.split(":").map(Number);

  let startTime = startHours * 60 + startMinutes;
  let endTime = endHours * 60 + endMinutes;

  // Consider midnight (00:00) as 1440 minutes for end time
  if (endHours === 0 && endMinutes === 0) {
    endTime = 1440;
  }

  return startTime <= endTime;
}

function isValidDate(date) {
  var dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  var matches = date.match(dateRegex);

  if (!matches) {
    return false;
  }

  var day = parseInt(matches[1], 10);
  var month = parseInt(matches[2], 10) - 1;
  var year = parseInt(matches[3], 10);
  var dateObj = new Date(year, month, day);

  return dateObj.getDate() === day && dateObj.getMonth() === month && dateObj.getFullYear() === year;
}

function isFutureDate(date) {
  var parts = date.split('/');
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1;
  var year = parseInt(parts[2], 10);
  var inputDate = new Date(year, month, day);
  var today = new Date();

  today.setHours(0, 0, 0, 0);

  return inputDate > today;
}

function isBeforeMinYear(date) {
  var parts = date.split('/');
  var year = parseInt(parts[2], 10);

  var minYear = 1900;

  return year < minYear;
}

$("body .reset-input-on-checked").on(
  "click",
  "input:checkbox",
  function (event) {
    var $checkbox = $(event.target);
    var $input = $checkbox
      .parents(".reset-input-on-checked")
      .find(':input:not([type="checkbox"])');

    if ($checkbox.is(":checked")) {
      $input.attr("disabled", true).val("");
    } else {
      $input.attr("disabled", false);
    }
  },
);

$("body").on("click", '[type="reset"]', (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $submit = $form.find('[type="submit"]');

  $submit.removeAttr("disabled");
});

$("body").on("click", "label[for]", (event) => {
  var $element = $(event.currentTarget);

  $("body")
    .find(`:input[id="${$element.attr("for")}"]:visible:not([readonly])`)
    .focus();
});

$("body").on("focus", ":input[readonly]", (event) => {
  var $element = $(event.currentTarget);

  $element.blur();
});

$("body").on("click", "[data-type-cpf]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $cpf = $form.find('[data-type="cpf"] :input');
  var $cnpj = $form.find('[data-type="cnpj"] :input');
  var $submit = $form.find('[type="submit"]');

  var cpfValue = $cpf.val();

  $cnpj.attr({ disabled: true, readonly: true });
  $cpf.removeAttr("disabled").removeAttr("readonly");

  if (isValidCpf(cpfValue)) {
    $submit.removeAttr("disabled");
  } else {
    $submit.attr("disabled", true);
  }

  if (cpfValue.length === 11) {
    if (isValidCpf(cpfValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CPF não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("body").on("click", "[data-type-cnpj]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $cpf = $form.find('[data-type="cpf"] :input');
  var $cnpj = $form.find('[data-type="cnpj"] :input');
  var $submit = $form.find('[type="submit"]');

  var cnpjValue = $cnpj.val();

  $cpf.attr({ disabled: true, readonly: true });
  $cnpj.removeAttr("disabled").removeAttr("readonly");

  if (isValidCnpj(cnpjValue)) {
    $submit.removeAttr("disabled");
  } else {
    $submit.attr("disabled", true);
  }

  if (cnpjValue.length === 14) {
    if (isValidCnpj(cnpjValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CNPJ não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("[data-type-cpf]:checked").click();
$("[data-type-cnpj]:checked").click();

$("body").on("input", "[data-cpf]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $submit = $form.find('[type="submit"]');
  var cpfValue = $element.val();

  if (cpfValue.length === 11) {
    if (isValidCpf(cpfValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CPF não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("body").on("input", "[data-cnpj]", (event) => {
  var $element = $(event.currentTarget);
  var $form = $element.parents("form");
  var $submit = $form.find('[type="submit"]');
  var cnpjValue = $element.val();

  if (cnpjValue.length === 14) {
    if (isValidCnpj(cnpjValue)) {
      $submit.removeAttr("disabled");
    } else {
      setTimeout(() => {
        alert("CNPJ não encontrado.");
      });

      $submit.attr("disabled", true);
    }
  }
});

$("body").on("input", "[data-cep]", (event) => {
  const $element = $(event.currentTarget);
  const $form = $element.closest("form");
  const $submit = $form.find('[type="submit"]');
  const cepValue = $element.val();
  const fields = ["#address_city", "#address_state", "#address_neighborhood", "#address_street"];

  function updateFields(data = {}) {
    fields.forEach((selector) => {
      const $field = $form.find(selector);
      const value = data[selector.replace("#address_", "")] || "";

      $field.val(value);
      value ? $field.attr("readonly", true) : $field.removeAttr("disabled readonly");
    });
  }

  updateFields();

  if (cepValue.length === 8) {
    cep(cepValue)
      .then((v) => {
        updateFields(v);
        $submit.removeAttr("disabled");
      })
      .catch(() => {
        updateFields();
        $submit.attr("disabled", true);
        fields.forEach((selector) => $form.find(selector).attr("disabled", true));

        setTimeout(() => alert("CEP não encontrado."), 100);
      });
  } else {
    fields.forEach((selector) => $form.find(selector).attr("disabled", true));
  }
});

$.each($("form"), function () {
  var $form = $(this);

  $.each($form.find("[data-mask]"), function () {
    var $input = $(this);

    Inputmask({
      mask: $input.data("mask"),
      autoUnmask: true,
      removeMaskOnSubmit: true,
      clearIncomplete: true,
    }).mask($input);
  });

  $.each($("input[data-time-range]"), function () {
    var $input = $(this);

    Inputmask({
      mask: "h9:m9 - h9:m9",
      placeholder: "HH:MM - HH:MM",
      clearIncomplete: true,
      definitions: {
        h: {
          validator: "[0-2]",
          cardinality: 1,
          prevalidator: null
        },
        m: {
          validator: "[0-4]",
          cardinality: 1,
          prevalidator: null
        }
      },
      oncomplete: function () {
        const value = $input.val();
        const [start, end] = value.split(" - ");

        if (!isValidTime(start) || !isValidTime(end) || !isStartBeforeEnd(start, end)) {
          $input.val("");
        }
      }
    }).mask($input);
  });

  $.each($form.find("[data-date]"), function () {
    var $input = $(this);

    Inputmask({
      mask: "99/99/9999",
      autoUnmask: true,
      clearIncomplete: true,
      placeholder: "DD/MM/YYYY",
    }).mask($input);

    $input.on('blur', function () {
      var value = $input.val();

      if (!value) {
        return;
      }

      var formattedValue = value.replace(/[^0-9]/g, '');

      formattedValue = formattedValue.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');

      if (value !== formattedValue) {
        $input.val(formattedValue);
      }

      if (!isValidDate(formattedValue)) {
        $input.val('');
        alert('Formato de data inválido. Por favor, insira uma data válida no formato DD/MM/YYYY.');
      } else if (isFutureDate(formattedValue)) {
        $input.val('');
        alert('A data não pode ser no futuro. Por favor, insira uma data válida.');
      } else if (isBeforeMinYear(formattedValue)) {
        $input.val('');
        alert('O ano não pode ser inferior a 1900. Por favor, insira uma data válida.');
      }
    });
  });

  $.each($form.find("[data-money]"), function () {
    var $input = $(this);

    Inputmask("currency", {
      autoUnmask: true,
      radixPoint: ",",
      groupSeparator: ".",
      allowMinus: false,
      prefix: "R$ ",
      suffix: " min",
      digits: 2,
      digitsOptional: false,
      rightAlign: false,
      unmaskAsNumber: true,
      removeMaskOnSubmit: true,
    }).mask($input);
  });

  // $.each($form.find("[type=password]"), function () {
  //   var $input = $(this);

  //   $input.on('blur', function () {
  //     var value = $input.val();
  //     var isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{6,}$/.test(value);

  //     if (!value) {
  //       return;
  //     }

  //     if (!isValid) {
  //       alert("A senha deve conter 6 caracteres, incluindo letra maiúscula, minúscula, número e caractere especial.");
  //       $input.val('');
  //     }
  //   });
  // });
});

$(document).ready(function() {
  const specialtyList = $('#specialty-list');
  const visibleItemsCount = specialtyList.data('visible-items');
  const totalItemsCount = specialtyList.find('.specialty-item').length;
  const itemsToToggle = specialtyList.find('.specialty-item:nth-child(n+' + (visibleItemsCount + 1) + ')');

  if (totalItemsCount > visibleItemsCount) {
    itemsToToggle.hide();

    const toggleButton = $('<button>', {
      class: 'toggle-list p-0 btn btn-link',
      text: 'Ver mais'
    });

    specialtyList.after(toggleButton);

    toggleButton.click(function() {
      const isVisible = itemsToToggle.is(':visible');

      itemsToToggle.slideToggle();
      $(this).text(isVisible ? 'Ver mais' : 'Ver menos');
    });
  }
});
