var $profile = $(".profiles #tabs");

if ($profile.length) {
  var url = location.href.replace(/\/$/, "");

  $profile.find('a[data-toggle="collapse"], a[data-toggle="tab"]').on("click", function (event) {
    event.preventDefault();
    event.stopPropagation();

    if ($(this).prop("disabled") || $(this).hasClass("disabled")) {
      return;
    }

    var href = $(this).attr("href");
    var tab = href.replace("?tab=", "");

    if (tab) {
      var newUrl = url.split("?")[0] + "?tab=" + tab;
      history.replaceState(null, null, newUrl);

      $profile.find(".nav-link, .tab-pane").removeClass("active show");

      $(this).addClass("active");
      $profile.find("#" + tab).addClass("active show");

      // COLLAPSE
      var $collapse = $profile.find("#collapse-" + tab);

      if ($collapse.length) {
        $collapse.collapse("show");
        $collapse.find("form").trigger("reset");
      }

      // TAB
      $profile.find("#" + tab).find("form").trigger("reset");
    }
  });

  var searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has("tab")) {
    var tab = searchParams.get("tab");

    if ($profile.find('.nav-tabs a[href="?tab=' + tab + '"]').length) {
      var $tabLink = $profile.find('.nav-tabs a[href="?tab=' + tab + '"]');

      if ($tabLink.length) {
        $profile.find(".nav-link, .tab-pane").removeClass("active show");
        $tabLink.addClass("active");
        $profile.find("#" + tab).addClass("active show");
      }

      var $collapse = $profile.find("#collapse-" + tab);

      if ($collapse.length) {
        $collapse.collapse("show");
      }
    }
  }
}

$('[data-tab="external"]').on("click", function (event) {
  var targetId = $(this).attr("data-target");
  var $modal = $(this).closest('.modal');

  if ($modal.length) {
    $modal.modal('hide');
  }

  $profile.find('.nav-link[href="?tab=' + targetId + '"]').click();
});

$('[data-focus]').on('click', function() {
  var $target = $($(this).data('focus'));

  if ($target.length) {
    setTimeout(function() {
      $('html, body').animate({ scrollTop: $target.offset().top - ($(window).height() / 2) + $target.outerHeight() / 2 }, 300);
      $target.focus();
    }, 300);
  }
});
